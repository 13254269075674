import React, {Component} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/footer'
import Header from './header'
import Script from 'react-load-script'
import CookieConsent, { Cookies } from "react-cookie-consent"
import { CookiesProvider } from 'react-cookie';
import ico from '../images/icons/favicon.ico'
import { useStaticQuery, graphql, Link, withPrefix } from "gatsby"
import PropTypes from "prop-types"
import './css/bootstrap.css'
import './css/style.css'
import './css/dark.css'
import './css/font-icons.css'
import './css/animate.css'
import './css/custom.css'

class Layout extends Component {
  constructor(props){
    super(props);
    
  this.children = props.children;
  }

  componentDidMount() {
    var tag = document.createElement('script');
    tag.async = false;
    tag.src = withPrefix('js/functions.js');
    tag.id = "myFunctions";
    var body = document.getElementsByTagName('body')[0];
    var temp = document.getElementById("myFunctions"); 
    if(temp){
      body.removeChild(temp);
    }
    body.appendChild(tag);
  }
  
  componentWillUnmount() {
    
  }

  render(){
    return (
    <>
      <Helmet>
        <link rel="shortcut icon" href={ico} />
        <script src={withPrefix('js/jquery.js')} type="text/javascript" />
        <script src={withPrefix('js/plugins.min.js')} type="text/javascript" />
      </Helmet>
      <Header/>
      <div>
        <CookiesProvider>
          <main>{this.children}</main>
        </CookiesProvider>
        <CookieConsent
          location="bottom"
          buttonText="OK, Thank You"
          cookieName="totmConsent"
          buttonStyle={{ background: "#1E629C", color: "#ffffff" }}
        >
          By continuing to use this website, you consent to the use of cookies
          in accordance with our&nbsp;
          <Link className="privacyPolicy" to="/privacy-policy">
            Privacy Policy.
          </Link>
        </CookieConsent>
       <Footer />
      </div>
    </>
    )
  }
    
}

/* const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <script src={withPrefix('js/jquery.js')} type="text/javascript" />
        <script src={withPrefix('js/plugins.min.js')} type="text/javascript" />
        <script src={withPrefix('js/functions.js')} type="text/javascript" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <CookiesProvider>
          <main>{children}</main>
        </CookiesProvider>
        <CookieConsent
          location="bottom"
          buttonText="OK, Thank You"
          cookieName="totmConsent"
          buttonStyle={{ background: "#1E629C", color: "#ffffff" }}
        >
          By continuing to use this website, you consent to the use of cookies
          in accordance with our&nbsp;
          <Link className="privacyPolicy" to="/privacy-policy">
            Privacy Policy.
          </Link>
        </CookieConsent>
       <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
} */

export default Layout