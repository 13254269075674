import React, { Component } from 'react';
import validator from 'email-validator';

class Contact extends Component {
    constructor(props){
        super(props);
        this.state =  {
          email: "",
          "name": "",
          message: "",
          submitted: false,
          show_message: false,
          show_error: false,
          form_message: null,
          form_error: null,
          token: null,
          disabled: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    validateForm(){
        let validity = {
            valid: true,
            message: null
        };

        if (this.state["name"].length == 0) {
            validity.message = "Please submit a valid name";
            validity.valid = false;
            return validity; 
        }
    
        else if (!validator.validate(this.state.email)) {
            validity.message = "Please submit a valid email address";
            validity.valid = false;
            return validity;     
        }
    
        else if (this.state.message.length < 2) {
            validity.message = "Please submit a valid message";
            validity.valid = false;
            return validity;
        }

        return validity;
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({
            submitted: true,
            token: await this.props.googleReCaptchaProps.executeRecaptcha('homepage')
        });
        let validity = this.validateForm();

        if(!validity.valid){
            this.setState({
                submitted: false,
                show_error: true,
                show_message: false,
                form_error: validity.message
            });
            return;
        }
        let values = {
            email: this.state.email,
            name: this.state.name,
            message: this.state.message,
            recaptcha: this.state.token
        };

        fetch("https://api.totmschool.com/contact", {
            body: JSON.stringify(values),
            method: 'POST'
        })
        .then(response => {
            this.setState({submitted: false});
            if (response.status >= 200 && response.status < 300) {
                this.setState({
                    show_message: true,
                    show_error: false,
                    form_message: "Your message was submitted successfully!",
                    refresh: true,
                    disabled: true
                });
              } else {
                this.setState({
                    show_message: false,
                    show_error: true,
                    form_error: JSON.parse(response.body).error
                });
              }
        }).catch(err => {
            this.setState({
                submitted: false,
                show_error: true,
                show_message: false,
                form_error: "Your message could not be submitted. Please try again!"
            });
            console.log(err)
        });

        this.setState({description: ''});
    }

    render(){
        return (
        <form className="quick-contact-form mb-0" onSubmit={this.handleSubmit}>
        <div className="form-process" style={{ display: this.state.submitted ? "block" : "none" }}>
            <div className="css3-spinner">
                <div className="css3-spinner-scaler"></div>
            </div>
        </div>
        <div className="input-group mx-auto">
            <div className="input-group-prepend">
                <div className="input-group-text"><i className="icon-user"></i></div>
            </div>
            <input type="text" className="required form-control" id="quick-contact-form-name" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder="Full Name" />
        </div>
        <div className="input-group mx-auto">
            <div className="input-group-prepend">
                <div className="input-group-text"><i className="icon-email2"></i></div>
            </div>
            <input type="text" className="required form-control email" id="quick-contact-form-email" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="Email Address" />
        </div>
        <textarea 
            className="required form-control input-block-level short-textarea" 
            id="quick-contact-form-message" 
            name="message" 
            rows="4" 
            cols="30" 
            value={this.state.message} 
            onChange={this.handleInputChange}
            placeholder="Message"></textarea>
        <button type="submit" id="quick-contact-form-submit" name="quick-contact-form-submit" className="btn btn-primary m-0" value="submit" disabled={this.state.disabled}>Send Email</button>
        <div style={{display: this.state.show_message ? "block" : "none", marginTop: "10px"}} className="alert alert-success" role="alert">
            {this.state.form_message}
        </div>
        <div style={{ display: this.state.show_error ? "block" : "none", marginTop: "10px" }} className="alert alert-danger" role="alert">
            {this.state.form_error}
        </div>
    </form>
    )
    }
}

export default Contact