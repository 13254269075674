import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import logo from "../images/logo.png"
import logo2x from "../images/logo@2x.png"
import PropTypes from "prop-types"

const Header = ({ siteTitle }) => {
  return (
    <header id="header" className="full-header transparent-header" data-sticky-class="not-dark">
			<div id="header-wrap">
				<div className="container">
					<div className="header-row">
						<div id="logo">
							<Link to="/" className="standard-logo" data-dark-logo="images/logo-dark.png"><img src={logo} alt="Canvas Logo" /></Link>
							<Link to="/" className="retina-logo" data-dark-logo="images/logo-dark@2x.png"><img src={logo2x} alt="Canvas Logo" /></Link>
						</div>

						<div id="primary-menu-trigger">
							<svg className="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
						</div>
						<nav className="primary-menu">

							<ul className="menu-container">
								<li className="menu-item">
									<Link className="menu-link" to="/"><div>Home</div></Link>
								</li>
								<li className="menu-item">
									<a className="menu-link" href="#"><div>About TOTM</div></a>
									<ul className="sub-menu-container">
										<li className="menu-item">
											<Link className="menu-link" to="/totm-school"><div>TOTM School</div></Link>
										</li>
										<li className="menu-item">
											<Link className="menu-link" to="/totm-history"><div>TOTM History</div></Link>
										</li>
										<li className="menu-item">
											<Link className="menu-link" to="/trader-psy"><div>Trader Psychology</div></Link>
										</li>
										<li className="menu-item">
											<Link className="menu-link" to="/golden-ratio"><div>1.618</div></Link>
										</li>
										<li className="menu-item">
											<Link className="menu-link" to="/food-for-thought"><div>Food For Thought</div></Link>
										</li>
										<li className="menu-item">
											<Link className="menu-link" to="/average-investor"><div>Average Investor</div></Link>
										</li>
									</ul>
								</li>
								<li className="menu-item">
									<a className="menu-link" href="#"><div>TOTM Tools</div></a>
									<ul className="sub-menu-container">
										<li className="menu-item">
											<Link className="menu-link" to="/totm-ideas"><div>Three Wise Men Trade Ideas</div></Link>
										</li>
										<li className="menu-item">
											<Link className="menu-link" to="/elliot-wave"><div>Elliot Wave Caluculator</div></Link>
										</li>
									</ul>
								</li>
								<li className="menu-item mega-menu">
									<Link className="menu-link" to="/newsletters"><div>TOTM Newsletters</div></Link>
								</li>
								<li className="menu-item mega-menu">
									<Link className="menu-link" to="/fishermen"><div>TOTM Fishermen</div></Link>
								</li>
								<li className="menu-item mega-menu">
									<Link className="menu-link" to="/testimonials"><div>Testimonials</div></Link>
								</li>
							</ul>

						</nav>
					</div>
				</div>
			</div>
			<div className="header-wrap-clone"></div>
		</header>
  )
}
Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}
export default Header