import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import disclaimer from '../data/disclaimer.json'
import Contact from './contact';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import  { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
const ReCaptchaContact = withGoogleReCaptcha(Contact);

const Footer = () => {
  return (
    <footer id="footer" className="dark">
        <div className="container">
            <div className="footer-widgets-wrap">
                <div className="row col-mb-50">
                    <div className="col-md-6 col-lg-4">
                        <div className="widget clearfix">
                            <h4>Foundations of Truth Ministry</h4>
                            <div className="footer-map">
                                <address>
                                    <strong>Headquarters:</strong><br/>
                                    1415 Highway 85N<br/>
                                    Suite 310 #380<br/>
                                    Fayetteville, Ga. 30214<br/>
                                </address>
                                <abbr title="Email Address"><strong>Email:</strong></abbr> <a href="mailto:info@totmschool.com">info@totmschool.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="widget clearfix">
                            <h4>Disclaimer</h4>
                            <p className="disclaimer">{disclaimer.disclaimer}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="widget quick-contact-widget form-widget clearfix">
                            <h4>Contact Us</h4>
                            <div className="form-result"></div>
                            <ReCaptchaContact />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="copyrights">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-auto text-center text-lg-left">
                        <p className="mb-3">Copyrights &copy; 2021 All Rights Reserved by TOTM</p>
                    </div>
                    <div className="col-12 col-lg-auto text-center text-lg-right">
                        <div className="copyrights-menu copyright-links mb-0">
                            <Link to="/">Home</Link>/
                            <Link to="/totm-school">TOTM School</Link>/
                            <Link to="/totm-ideas">Three Wise Men Stock Ideas</Link>/
                            <Link to="/newsletters">TOTM Newsletters</Link>/
                            <Link to="/totm-fishermen">TOTM Fishermen</Link>/
                            <Link to="/testimonials">Testimonials</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}
export default Footer
